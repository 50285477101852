import React from "react";
import {
  StyledImageList,
  StyledImageListItems,
  StyledImageListItem,
  StyledImageListImage,
  StyledImageListLabel,
} from "./style.jsx";
import Scroller from "../Scroller/Scroller";

export default ({ 
  items, 
}) => {
  return (
    <StyledImageList>
      <Scroller>
        <StyledImageListItems>
          {items.map((item, index) => (
            <StyledImageListItem
              key={index}
            >
              <StyledImageListImage src={item.image} loading="lazy" />
              <StyledImageListLabel
                dangerouslySetInnerHTML={{ __html: item.label }}
              />
            </StyledImageListItem>
          ))}
        </StyledImageListItems>
      </Scroller>
    </StyledImageList>
  );
};
